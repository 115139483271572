import { Component, effect } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { FbPixelService } from '@shared/services/fb-pixel.service';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { user } from '@angular/fire/auth';

@Component({
  selector: 'blu-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  menuDrawerVisible = false;
  constructor(
    public sharedService: SharedService,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    private authService: AuthService,
    private fbPixelService: FbPixelService,
  ) {
    effect(async () => {
      const params = this.route.snapshot.queryParamMap;
      const user = this.userService.user();
      const org = this.userService.organization();
      if (user === undefined && org === undefined) return;
      if ((user || org) && (!user || !org)) return;

      // handle stripe checkout redirect
      const event = params.get('event');
      const token = params.get('t');

      if (event) {
        const name = decodeURI(event);
        this.analyticsService.logEvent(name);
        this.fbPixelService.track(name);
        if (!token) {
          return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { event: null },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }
      }

      if (token) {
        try {
          await this.authService.signInWithCustomToken(token);
        } catch {
          return this.router.navigateByUrl('/sign-in/admin');
        }
        return this.router.navigate([{ outlets: { modal: ['plans'] } }], {
          relativeTo: this.route,
          queryParams: { t: null, event: null },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }

      if (user?.type !== 'admin') {
        return this.router.navigateByUrl('/sign-in/admin');
      }

      const endDate = org?.subscription?.current_period_end?.toMillis();
      if (endDate && Date.now() < endDate) {
        return this.router.navigate([{ outlets: { modal: null } }], {
          relativeTo: this.route,
        });
      }
      return this.router.navigate([{ outlets: { modal: ['plans'] } }], {
        relativeTo: this.route,
      });
    });
  }
}
