import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedAuthService } from 'libs/shared/src/lib/services/auth.service';
import { AuthService } from '../../services/auth.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'blu-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  userMenuVisible = false;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public sharedAuthService: SharedAuthService,
    public sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService,
  ) {}

  closeUserMenu(tar: any): void {
    this.userMenuVisible = tar.visible;
  }

  openPlansModal(): void {
    this.router.navigate([{ outlets: { modal: ['plans'] } }], {
      relativeTo: this.route,
    });
  }
}
