import { Component, OnDestroy } from '@angular/core';
import { onSnapshot, Unsubscribe } from '@angular/fire/firestore';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';

type Plan = {
  name: string;
  price: string;
  priceId: string;
  features: string[];
};

const plans = [
  {
    name: 'Individual',
    price: '127,00 / mês',
    priceId: 'price_1Lyik9DVDMdAeYWlI06siTaw',
    features: [
      '2 chaves para disparo',
      '1 canal de chatbot',
      '2 chaves para atendimento',
      '1 aquecimento de chip',
    ],
  },
  {
    name: 'Básico',
    price: '297,00 / mês',
    priceId: 'price_1LDxCADVDMdAeYWl0W4IR1Ag',
    features: [
      '10 chaves para disparo',
      '1 canal de chatbot',
      '2 chaves para atendimento',
      '2 aquecimentos de chip',
    ],
  },
  {
    name: 'Pro',
    price: '597,00 / mês',
    priceId: 'price_1LDxCeDVDMdAeYWlVMAibhLv',
    features: [
      '40 chaves para disparo',
      '1 canal de chatbot',
      '2 chaves para atendimento',
      '3 aquecimentos de chip',
    ],
  },
];

@Component({
  selector: 'blu-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
  host: { class: 'modal-wrapper' },
})
export class PlansComponent implements OnDestroy {
  checkoutLoading = false;
  plans: Plan[] = plans;

  subscriptions: Unsubscribe[] = [];

  constructor(
    public userService: UserService,
    private firestore: SharedFirestoreService,
    public sharedService: SharedService,
    public analyticsService: AnalyticsService,
  ) {}

  async createSessionLink(plan: Plan): Promise<void> {
    this.checkoutLoading = true;
    const userId = this.userService.user()?.uid;
    if (!userId) throw new Error('User not found');
    const analyticsClientId = await this.analyticsService.getClientId();

    const docRef = await this.firestore.addDoc(
      `admin/${userId}/checkout_sessions`,
      {
        price: plan.priceId,
        // allow_promotion_codes: true,
        success_url: `${window.location.origin}/admin/send/stats?event=admin_plan_${encodeURI(plan.name)}_checkout_success`,
        cancel_url: `${window.location.origin}/admin/send/stats?event=admin_plan_${encodeURI(plan.name)}_checkout_failure`,
        trial_period_days: this.userService.organization()?.subscription
          ? 0
          : 3,
        locale: 'pt',
        metadata: { analyticsClientId: analyticsClientId || userId },
        billing_address_collection: 'auto',
      },
      false,
    );

    // then listen to url and navigate when set
    const unsubscribe = onSnapshot(docRef, (sessionDocSnap) => {
      const checkoutSession = sessionDocSnap.data();
      if (!checkoutSession?.['url']) return;
      this.checkoutLoading = false;
      window.location.href = checkoutSession?.['url'];
    });
    this.subscriptions.push(unsubscribe);
  }

  ngOnDestroy(): void {
    for (const unsubscribe of this.subscriptions) {
      unsubscribe();
    }
  }
}
