<aside class="shared-side-bar">
  <!-- close button -->

  @if (sharedService.screenSize === 's') {
    <span
      class="material-symbols-rounded absolute top-4 right-4 cursor-pointer"
      (click)="close.emit(); analyticsService.logEvent('admin_nav_close_click')"
    >
      close
    </span>
  }

  <!-- sections -->
  @for (section of sections; track section.label) {
    <h2 class="flex items-center justify-between">
      {{ section.label }}
    </h2>
    <nav>
      <ol class="p-0 m-0">
        @for (page of section.pages; track page.label) {
          <li
            class="blu-menu-item"
            [routerLink]="page.routerLink"
            [routerLinkActive]="'active'"
          >
            <span class="material-symbols-rounded"> {{ page.icon }} </span>
            {{ page.label }}
          </li>
        }
      </ol>
    </nav>
    @if (!$last) {
      <hr class="m-0" />
    }
  }

  <!-- functions -->
  <hr class="mb-0 mt-auto" />
  <a
    href="https://home.blu.direct/blog/visao-geral-painel-administrador"
    target="_blank"
    class="no-underline text-black/90 blu-menu-item"
  >
    <span class="material-symbols-rounded"> help </span>
    Ajuda
  </a>
</aside>
