<div
  class="card"
  [style.height]="sharedService.screenSize === 'l' ? '480px' : '100%'"
  [style.width]="sharedService.screenSize === 'l' ? '720px' : '100%'"
>
  <!-- header -->
  <header class="header">
    <h2>Editar conta</h2>
    <kor-icon
      button
      icon="close"
      (click)="sharedService.closeModal(route)"
    ></kor-icon>
  </header>

  <!-- grid -->
  <section
    class="w-full flex flex-col gap-4 overflow-auto overflow-y-auto h-full"
  >
    <div>
      <p>{{ userService.organization()?.name }}</p>
    </div>
    <div class="flex justify-between">
      <a
        class="w-fit capitalize py-0.5 px-2 rounded-md border border-solid no-underline text-black/90 transition-all"
        target="_blank"
        [ngClass]="{
          'bg-emerald-500/20 border-emerald-500':
            userService.organization()?.subscription?.plan === 'individual',
          'bg-orange-500/20 border-orange-500':
            userService.organization()?.subscription?.plan === 'plus',
          'bg-violet-500/20 border-violet-500':
            userService.organization()?.subscription?.plan === 'basico',
          'bg-pink-500/20 border-pink-500':
            userService.organization()?.subscription?.plan === 'pro'
        }"
        >{{ userService.organization()?.subscription?.plan }}</a
      >
      <kor-button
        slot="footer"
        color="tertiary"
        (click)="
          createDashboardLink();
          analyticsService.logEvent('header_subscription-tag_click')
        "
        label="Editar plano"
      >
      </kor-button>
    </div>
    <div class="mt-8">
      <kor-button
        slot="footer"
        color="tertiary"
        (click)="
          alertDeleteAccount();
          analyticsService.logEvent('header_delete-account_click')
        "
        label="Deletar conta e dados"
        [disabled]="showDeletionConfirmation()"
      >
      </kor-button>
      @if (showDeletionConfirmation()) {
        <div class="mt-4">
          <p class="text-red-500">
            Sua solicitação foi enviada com sucesso e será processada em até 7
            dias úteis.
          </p>
        </div>
      }
    </div>
  </section>
</div>
