<form
  [formGroup]="loginForm"
  (ngSubmit)="onSubmit()"
  class="flex flex-col gap-2 flex-1"
>
  <p class="mt-auto mb-2">Utilize suas credenciais para entrar.</p>

  <!-- inputs -->
  <div class="flex flex-col gap-2">
    <input
      id="email"
      formControlName="email"
      type="email"
      required
      autocomplete="email"
      placeholder="Email"
      autofocus
    />
    <input
      id="password"
      formControlName="password"
      type="password"
      required
      autocomplete="current-password"
      placeholder="Senha"
    />
  </div>

  <!-- forgot password -->
  <a
    href="javascript:void(0);"
    (click)="
      resetPassword();
      analyticsService.logEvent('sign-in_admin_reset-password_click')
    "
    class="text-accent-600 font-bold"
    >Esqueceu sua senha?</a
  >

  <!-- messages -->
  <b
    class="font-bold text-green-600 min-h-6"
    [innerHTML]="authService.loginSuccess | async"
  ></b>
  <p
    [innerHTML]="authService.loginError | async"
    class="font-bold min-h-6 text-red-600"
  ></p>

  <!-- cta -->
  <button
    type="submit"
    [disabled]="!loginForm.valid"
    (click)="analyticsService.logEvent('sign-in_admin_sign-in_click')"
  >
    Entrar
  </button>
  <hr class="m-1" />
  <button
    class="secondary gap-2 !bg-accent-950 hover:!bg-accent-900 !text-white"
    (click)="sharedAuthService.handleGoogleSignIn()"
  >
    <img height="16" width="16" src="assets/icons/google-logo.png" />
    Entrar com Google
  </button>
</form>
