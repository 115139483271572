import { Injectable } from '@angular/core';
declare let fbq: (event: string, eventName: string) => void;
@Injectable({
  providedIn: 'root',
})
export class FbPixelService {
  track(eventName: string) {
    fbq('track', eventName);
  }
}
