import { Component, effect } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'blu-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminSignInComponent {
  tabs = [
    { label: 'Entrar', id: 'sign-in', path: 'admin' },
    { label: 'Criar conta', id: 'register', path: 'admin/register' },
  ];
  constructor(
    public router: Router,
    public analyticsService: AnalyticsService,
    private userService: UserService,
  ) {
    effect(() => {
      const user = this.userService.user();
      if (user?.type !== 'admin') return;
      this.router.navigateByUrl('/admin');
    });
  }
}
