import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Auth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
} from '@angular/fire/auth';
import {
  getAdditionalUserInfo,
  GoogleAuthProvider,
  signInWithPopup,
} from '@firebase/auth';
import { environment } from '@shared/environments/environment';
import { firstValueFrom, ReplaySubject, Subject } from 'rxjs';
import {
  AdminLoginError,
  AuthUser,
  FirestoreCollections,
  IOrganization,
} from '../interfaces';
import { serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { SharedFirestoreService } from './firestore.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SharedAuthService {
  loginError = new ReplaySubject<string>(1);
  loginSuccess = new ReplaySubject<string>(1);
  authChange$ = new Subject<any>();

  constructor(
    private auth: Auth,
    private http: HttpClient,
    private firestore: SharedFirestoreService,
    private route: ActivatedRoute,
  ) {}

  initAuth(): void {
    onAuthStateChanged(this.auth, (user) => {
      if (typeof window !== 'undefined' && 'clarity' in window && user?.uid) {
        (window.clarity as any)('set', 'userId', user.uid);
      }
      this.authChange$.next(user);
    });
  }

  async register(
    email: string,
    password: string,
    displayName: string,
    phoneNumber?: string,
  ): Promise<void | AuthUser> {
    const body = {
      email,
      password,
      displayName,
      phoneNumber: phoneNumber && `+55${phoneNumber}`,
    };
    try {
      const userAuth = await firstValueFrom(
        this.http.post<AuthUser>(
          environment.cloudFunctionsApiUrl + '/auth',
          body,
        ),
      );
      return userAuth;
    } catch (error) {
      let errorCode =
        (error as any).error?.error ||
        (error as any).code ||
        (error as any).error;
      const code = (error as any).error?.code;
      if (code && code === 'auth/email-already-exists') {
        errorCode = `Email já em uso. Se este é o seu email, por favor <a class="link" href="https://blu.direct/sign-in/admin?email=${email}">faça o login</a>.`;
      }
      this.displayErrorMessage(errorCode);
    }
  }

  async signIn(
    email?: string,
    password?: string,
  ): Promise<UserCredential | undefined> {
    if (!email || !password) return;
    try {
      const credentials = await signInWithEmailAndPassword(
        this.auth,
        email,
        password,
      );
      return credentials;
    } catch (error) {
      const errorCode = (error as any).code as keyof typeof AdminLoginError;
      const errorMessage = AdminLoginError[errorCode] || errorCode;
      this.loginError.next(errorMessage);
    }
    return;
  }

  async resetPassword(email: string | undefined): Promise<void> {
    if (!email) return;
    try {
      await sendPasswordResetEmail(this.auth, email);
      this.loginError.next('');
      this.loginSuccess.next(
        'Email enviado com as instruções para resetar a senha.',
      );
    } catch (error) {
      this.displayErrorMessage((error as any).code);
    }
  }

  displayErrorMessage(errorCode: string) {
    const errorMessage =
      AdminLoginError[errorCode as keyof typeof AdminLoginError] || errorCode;
    this.loginError.next(errorMessage);
  }

  async getToken() {
    const user: User | null = await new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
    if (!user) throw new Error('No user logged in');
    return user.getIdToken();
  }

  async signInWithGoogle(): Promise<UserCredential | null> {
    try {
      const provider = new GoogleAuthProvider();
      return await signInWithPopup(this.auth, provider);
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      return null;
    }
  }

  async handleGoogleSignIn(): Promise<AuthUser | undefined> {
    const userCredential = await this.signInWithGoogle();
    if (!userCredential || !userCredential.user.email) {
      throw new Error('No email found');
    }
    const additionalInfo = getAdditionalUserInfo(userCredential);
    if (!additionalInfo || !additionalInfo.isNewUser) return;
    const authUser = {
      displayName: userCredential.user.displayName,
      uid: userCredential.user.uid,
      email: userCredential.user.email,
      phoneNumber: userCredential.user.phoneNumber || '',
    } as AuthUser;
    await this.createOrg(authUser);
    await this.createAdmin(authUser);
    return authUser;
  }

  async createOrg(user: AuthUser): Promise<void> {
    await this.firestore.addDoc<Omit<IOrganization, 'uid'>>(
      FirestoreCollections.Organization,
      {
        name: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        subscription: null,
        admins: [user.uid],
        createdAt: serverTimestamp() as Timestamp,
        utm_source: this.route.snapshot.queryParams['utm_source'] || null,
        utm_medium: this.route.snapshot.queryParams['utm_medium'] || null,
        utm_campaign: this.route.snapshot.queryParams['utm_campaign'] || null,
        onboarding: {
          createdUser: { completedAt: null, dismissedAt: null },
          createdCampaign: { completedAt: null, dismissedAt: null },
          createdFlow: { completedAt: null, dismissedAt: null },
          createdChannel: { completedAt: null, dismissedAt: null },
          createdOperator: { completedAt: null, dismissedAt: null },
          usedTemplateLink: { completedAt: null, dismissedAt: null },
        },
      },
    );
  }

  async createAdmin(user: AuthUser): Promise<void> {
    this.firestore.setDoc(
      FirestoreCollections.Admin,
      user.uid,
      {
        name: user.displayName,
        email: user.email,
        uid: user.uid,
        createdAt: serverTimestamp(),
        phoneNumber: user.phoneNumber,
        utm_source: this.route.snapshot.queryParams['utm_source'] || null,
        utm_medium: this.route.snapshot.queryParams['utm_medium'] || null,
        utm_campaign: this.route.snapshot.queryParams['utm_campaign'] || null,
      },
      { merge: true },
    );
  }

  signOut(): void {
    signOut(this.auth);
  }
}
