import { Component, inject } from '@angular/core';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { AuthService } from '@app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedAuthService } from '@shared/services/auth.service';

@Component({
  selector: 'blu-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  private fb = inject(FormBuilder);
  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  constructor(
    public authService: AuthService,
    public sharedAuthService: SharedAuthService,
    public analyticsService: AnalyticsService,
    private route: ActivatedRoute,
  ) {
    if (this.route.snapshot.queryParams['email']) {
      this.loginForm.controls['email'].setValue(
        this.route.snapshot.queryParams['email'],
      );
    }
  }

  onSubmit(): void {
    // Handle the login logic here
    if (this.loginForm.valid) {
      this.authService.signIn(
        this.loginForm.value.email,
        this.loginForm.value.password,
      );
    }
  }

  resetPassword(): void {
    this.authService.resetPassword(this.loginForm.value.email);
  }
}
