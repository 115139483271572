import { Injectable } from '@angular/core';
import {
  Analytics,
  getAnalytics,
  logEvent,
  getGoogleAnalyticsClientId,
} from '@angular/fire/analytics';

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private analytics: Analytics = getAnalytics()) {}

  logEvent(name: string, options?: any): void {
    logEvent(this.analytics, name, options);
  }

  getClientId() {
    return Promise.race([
      getGoogleAnalyticsClientId(this.analytics),
      sleep(300),
    ]);
  }
}
