import { Component, inject } from '@angular/core';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { AuthService } from '@app/services/auth.service';
import { UserService } from '@app/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedAuthService } from '@shared/services/auth.service';

@Component({
  selector: 'blu-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  loading = false;
  private fb = inject(FormBuilder);
  registrationForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    orgName: ['', Validators.required],
    phoneNumber: ['', Validators.required],
  });

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public sharedAuthService: SharedAuthService,
    public analyticsService: AnalyticsService,
  ) {}

  async onSubmit(): Promise<void> {
    this.loading = true;
    if (!this.registrationForm.valid) {
      this.loading = false;
      return;
    }
    const form = this.registrationForm.value;
    const authUser = await this.authService.register(
      form.email,
      form.password,
      form.orgName,
      form.phoneNumber,
    );
    if (!authUser) {
      this.loading = false;
      return;
    }
    // the order here is important, first create org
    await this.sharedAuthService.createOrg(authUser);
    await this.sharedAuthService.createAdmin(authUser);
    this.loading = false;
    this.analyticsService.logEvent('sign-in_admin_register_click');
  }
}
