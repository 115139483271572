export const environment = {
  firebase: {
    projectId: 'chat-blu',
    appId: '1:987574111633:web:3a818f6e939474ceb55e30',
    storageBucket: 'chat-blu.appspot.com',
    apiKey: 'AIzaSyBqkPLoFTDtQIATXK_9KDePzIsAK5iMbSY',
    authDomain: 'chat-blu.firebaseapp.com',
    messagingSenderId: '987574111633',
    measurementId: 'G-765E85F15G',
    databaseURL: 'https://chat-blu-default-rtdb.firebaseio.com',
  },
  production: true,
  cloudFunctionsApiUrl:
    'https://southamerica-east1-chat-blu.cloudfunctions.net',
  bluApiUrl: 'https://ws4.blu.direct',
  devBluApiUrl: 'https://ws4.blu.direct:4000',
  homeUrl: 'https://home.blu.direct',
  clarityId: 'nk3go64kje',
  cmsPostsUrl:
    'https://posts-5jrpujz6gq-uc.a.run.app?project=Y5qgvtBpxUZ5khPM7qQs',
  cmsPostBySlugUrl:
    'https://postbyslug-5jrpujz6gq-uc.a.run.app?project=Y5qgvtBpxUZ5khPM7qQs',
};
