import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'blu-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class AdminNavBarComponent {
  constructor(
    public router: Router,
    public sharedService: SharedService,
    public analyticsService: AnalyticsService,
    public userService: UserService,
  ) {}

  sections = [
    {
      label: 'Disparos',
      pages: [
        {
          label: 'Estatísticas',
          icon: 'insights',
          routerLink: '/admin/send/stats',
        },
        {
          label: 'Chaves de disparo',
          icon: 'lock_open',
          routerLink: '/admin/send/users',
        },
        {
          label: 'Modelos de disparo',
          icon: 'wysiwyg',
          routerLink: '/admin/send/templates',
        },
      ],
    },
    {
      label: 'Aquecimento',
      pages: [
        {
          label: 'Chips em aquecimento',
          icon: 'local_fire_department',
          routerLink: '/admin/warmup',
        },
      ],
    },
    {
      label: 'Atendimento',
      pages: [
        {
          label: 'Chatbots',
          icon: 'account_tree',
          routerLink: '/admin/chat/flows',
        },
        {
          label: 'Canais',
          icon: 'phonelink_ring',
          routerLink: '/admin/chat/channels',
        },
        {
          label: 'Atendentes',
          icon: 'support_agent',
          routerLink: '/admin/chat/operators',
        },
      ],
    },
  ];

  @Output() close = new EventEmitter();
}
