<h1><img src="favicon.png" height="48" width="48" />blü</h1>

<div class="card">
  <!-- TODO: replace ngIf syntax with @if -->
  <qrcode
    *ngIf="bluApi.qr$ | async as qr; else spinner"
    [qrdata]="qr"
    [width]="200"
  ></qrcode>
  <p>Escaneie o código acima no <b>WhatsApp</b> até receber a confirmação.</p>
</div>

<ng-template #spinner>
  <kor-spinner></kor-spinner>
</ng-template>
